import request from '@/api/request.js'

/**
 * @Description: 箱型
 */

// 分页查询
export function pageWmsBoxModel(data) {
    return request({
        url: '/api/wmsBoxModel/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsBoxModel(data) {
    return request({
        url: '/api/wmsBoxModel/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsBoxModel(data) {
    return request({
        url: '/api/wmsBoxModel/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsBoxModel(data) {
    return request({
        url: '/api/wmsBoxModel/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsBoxModel(data) {
    return request({
        url: '/api/wmsBoxModel/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsBoxModel(data) {
    return request({
        url: '/api/wmsBoxModel/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsBoxModel(data) {
    return request({
        url: '/api/wmsBoxModel/batchDelete',
        method: 'POST',
        data
    })
}

